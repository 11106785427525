.media__library {
  width: 100%;
}

.media__library .media__header {
  display: flex;
  justify-content: space-between;
}

.media__library .media__header > .media__header-left {
  display: flex;
  column-gap: 8px;
}

.media__library
  .media__header
  > .media__header-left
  .media__search-tags
  .media__search-tags-source {
  margin-bottom: 1rem;
}

.media__library .media__header > .media__header-left .media__search-tags {
  display: flex;
}

.media__library
  .media__header
  > .media__header-left
  .media__search-tags
  .media__search-tags-input {
  max-width: 200px;
  height: 30px;
  padding: 0 11px;
  text-align: left;
  background-color: #fff;
  border: 1px solid var(--color-stroke);
  transition: all 0.3s linear;
}

.media__library
  .media__header
  > .media__header-left
  .media__search-tags
  .media__search-btn {
}

.media__library
  .media__header
  > .media__header-left
  .media__search-tags
  .media__search-tags-list {
  margin-bottom: 1rem;
}

.media__library
  .media__header
  > .media__header-left
  .media__search-tags
  .media__search-tags-list
  .ant-tag {
  height: 32px;
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  padding: 2px 14px;
}

.media__library
  .media__header
  > .media__header-left
  .media__search-tags
  .media__search-tags-source
  .media__search-tags-tag {
  display: flex;
  height: 32px;
  align-items: center;
  background: #fff;
  border-style: dashed;
  font-size: 14px;
  padding: 2px 14px;
}

.media__library .media__header > .media__header-left .media__search-limit {
  margin-bottom: 1rem;
}

.media__library
  .media__header
  > .media__header-left
  .media__search-limit-label {
}

.media__library
  .media__header
  > .media__header-left
  .media__search-limit-input {
}

.media__library .media__header > .media__header-right .media__select-playlist {
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  justify-content: flex-end;
}

.media__library
  .media__header
  > .media__header-right
  .media__select-playlist
  .ant-select {
  width: 200px !important;
}

.media__library .media__header > .media__header-right .media__select-add {
  display: flex;
  justify-content: flex-end;
}

.media__library
  .media__header
  > .media__header-right
  .media__select-add
  > .btn-add-playlist {
  width: 200px;
}

.media__library
  .media__header
  > .media__header-right
  .media__select-add
  > .btn-add-youtube {
  width: 200px;
}

.media__library
  .media__header
  > .media__header-right
  .media__select-add
  > .btn-add-youtube {
  margin-right: 24px;
  background-color: #fff !important;
  border: 1px solid hsla(0, 100%, 50%, 0.64);
  color: var(--color-primary);
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.media__library
  .media__header
  > .media__header-right
  .media__select-add
  > .btn-add-youtube:hover {
  background-color: red !important;
  color: var(--color-primary);
  color: #fff !important;
}

.media__library .media__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
}

.media__library .media__content .media__list {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
}

.media__library .media__content .media__list .media__item {
  width: 275px;
}

.media__library .media__content .media__list .media__item .media__item-img {
  background-position: center;
  background-size: cover;
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.media__library .media__content .media__list .media__item .media__item-actions {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  z-index: 30;
}

.media__library
  .media__content
  .media__list
  .media__item
  .media__item-actions
  .media__item-check {
  background-color: var(--color-primary);
  color: #fff;
  height: 30px;
  width: 30px;
  border-left: 1px solid #fff;
  padding: 0;
  margin: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.media__library
  .media__content
  .media__list
  .media__item
  .media__item-actions
  .media__item-check
  .media__check-logo {
  width: 15px;
  height: 15px;
}

.media__library
  .media__content
  .media__list
  .media__item
  .media__item-actions
  .media__preview {
  padding: 0.25rem 0.5rem;
  outline: none;
  border: none;
  cursor: pointer;
  border-bottom-left-radius: 10px;
  background-color: var(--color-primary);
  color: #fff;
}

.media__library
  .media__content
  .media__list
  .media__item
  .media__item-actions
  .media__item-delete {
  padding: 0.25rem 0.5rem;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: var(--color-primary);
  color: #fff;
  border-left: 1px solid #fff;
}

.media__library
  .media__content
  .media__list
  .media__item
  .media__item-description {
  position: absolute;
  left: 0;
  bottom: 0;
  color: #fff;
  padding: 10px;
  font-size: 14px;
  line-height: normal;
  width: 100%;
  z-index: 50;
  background: linear-gradient(180deg, transparent -52.78%, rgba(0, 0, 0, 0.56));
}

.media__library
  .media__content
  .media__list
  .media__item
  .media__item-description
  span {
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  display: block;
  white-space: pre-wrap;
}

.media__library
  .media__content
  .media__list
  .media__item
  .media__item-description
  p {
  font-size: 13px;
  font-weight: 500;
  line-height: 19px;
  white-space: pre-wrap;
}