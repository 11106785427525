[class*=' rbc-'],
[class^='rbc-'] {
  font-family: 'Poppins', sans-serif !important;
}

.is-required {
  color: var(--color-danger);
}
input.ant-input,
.ant-input-password,
textarea.ant-input {
  border-radius: 6px !important;
  border-color: var(--color-secondary) !important;
}

.ant-select.has-error .ant-select-selector {
  border-color: var(--color-danger) !important;
}

input.ant-input.has-error,
.ant-input-password.has-error,
textarea.ant-input.has-error {
  border-color: var(--color-danger) !important;
}

.error-message {
  color: var(--color-danger);
}

.ant-tooltip-inner {
  border-radius: 5px !important;
  font-family: 'Poppins', sans-serif;
  font-size: 0.8em;
  display: flex;
  align-items: center;
  min-height: 29px !important;
}

.ant-pagination {
  margin: 0px !important;
  margin-top: 1rem !important;
}

.ant-pagination > li {
  margin-top: 0px !important;
  font-family: 'Poppins', sans-serif;
}

.ant-pagination > li > a,
.ant-pagination > li > button {
  color: var(--color-secondary);
}

.ant-pagination > li,
.ant-pagination > li > button {
  border: none !important;
  background-color: transparent !important;
}

.ant-pagination-item:focus-visible a,
.ant-pagination-item:hover a,
.ant-pagination-next button:hover,
.ant-pagination-prev button:hover,
.ant-pagination-item-active a {
  color: var(--color-active) !important;
}

.ant-select {
  width: 100% !important;
}

.ant-select-selector {
  border-radius: 5px !important;
  border: 1px solid var(--color-stroke) !important;
}

.ant-checkbox-wrapper > span {
  color: var(--color-black);
}

.ant-picker-cell .ant-picker-cell-inner {
  text-align: center !important;
}

.container {
  max-width: 100% !important;
}

.content {
  margin-bottom: 0px !important;
  border-bottom: 1px solid var(--color-stroke) !important;
}

.breadcrumb-active {
  color: var(--color-active);
}

h1.page-title {
  font-size: 1.25rem;
  font-weight: 500;
  color: var(--color-active);
  margin: 1.5rem 0rem;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.button-primary {
  background-color: var(--color-primary);
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
}

.button-primary > i {
  margin-right: 0.5rem;
}

.button-primary:hover {
  opacity: 0.8;
}

.header-right-content {
  display: flex;
  align-items: flex-start;
}

.searchbar {
  margin-right: 0.5rem;
  border: 1px solid var(--color-stroke);
  padding: 0.4rem 1rem;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.search-error {
  margin-top: 0.25rem;
  color: var(--color-danger);
  font-size: small;
}

.searchbar > i {
  margin-right: 0.5rem;
  color: var(--color-navbar);
  font-size: 1.2em;
}

.searchbar > input {
  border: none;
  background-color: transparent;
}

.searchbar > input:focus {
  outline: none;
}

.button-secondary {
  background-color: #fff;
  border: 1px solid var(--color-primary);
  border-radius: 10px;
  color: var(--color-primary);
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.button-secondary:hover {
  opacity: 0.8;
}

.button-secondary > i {
  margin-right: 0.5rem;
}

.button-loading {
  opacity: 0.5;
  cursor: not-allowed;
}

.mt-2 {
  margin-top: 2rem;
}

.mr-1 {
  margin-right: 1rem;
}

.mr-1\/5 {
  margin-right: .5em;
}

.mb-2 {
  margin-bottom: 2rem;
}

.custom-modal.custom-modal-lg {
  width: 867px !important;
}

.custom-modal .ant-modal-content {
  padding: 0px;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: .5em;
}

.custom-modal .ant-modal-body {
  padding: 0px;
  background-color: #000;
  width: 100%;
  height: 100%;
  border-radius: .5em;
}

.custom-modal .ant-modal-body img {
  width: 100%;
  height: 350px;
  object-fit: contain;
}

.custom-modal .ant-modal-close {
  background-color: #fff;
  border-radius: 50%;
  margin-right: -1.5em;
  margin-top: -2em;
  box-shadow: 0px 17px 11px rgba(0, 0, 0, 0.25);
}

.custom-modal-content {
  background-color: #fff;
  padding: 2em;
  border-radius: 0px 0px .5em .5em;
}

.custom-modal-content .custom-modal-content-title {
  display: flex;
  margin-bottom: 1em;
}

.custom-modal-content .custom-modal-content-title .custom-modal-title {
  font-weight: bold;
  color: #828282;
  margin-right: 1.5em;
  min-width: 10.5em;
}

.custom-modal-content .custom-modal-content-title .custom-modal-description::before {
  content: ':';
  margin-right: 0.5em;
  margin-left: -0.8em;
}

.ant-table-thead tr > th {
  background-color: #c2cad9 !important;
  color: #22486c !important;
}

.ant-table-thead tr > th:first-child {
  border-top-left-radius: 5px !important;
}

.ant-table-thead tr > th:last-child {
  border-top-right-radius: 5px !important;
}