.media__library {
  width: 100%;
}

.media-preview-modal p {
  font-family: Poppins, sans-serif;
  margin-top: 1rem;
  text-align: justify;
}

.media__library .header__search-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.media__library .header__search-wrapper .media-select .select-dropdown {
  width: 100%;
  margin-bottom: 0;
}

/* .media__library .select-dropdown {
  width: fit-content;
  padding: auto;
}
.media__library .select-dropdown .select-dropdown-name {
  display: block;
}
.media__library .select-dropdown-item {
  height: auto;
  max-width: fit-content;
} */

/* .media__library .select-playlist-dropdown {
  width: fit-content;
  flex-direction: row;
  padding: auto;
  margin-left: 30px;
} */

.media__library .select-playlist-dropdown .select-playlist-dropdown__tag {
  display: block;
}

.media__library .media-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  align-items: flex-start;
}

.media__library .media-header .searchbar {
  display: flex;
  align-items: center;
  width: 15%;
  border-radius: 0.5rem;
  border: 1px solid var(--color-secondary);
  padding: 0.3rem 0.7rem;
  margin-right: 0.7rem;
}
.media__library .media-header .search-items {
  display: flex;
  align-items: center;
  /* width: 15%; */
}
.media__library .search-error {
  display: flex;
  align-items: flex-end;
  text-align: end;
  padding-right: 5px;
  justify-content: flex-end;
  margin-bottom: 1rem;
  /* width: 15%; */
}

.media__library .media-header .searchbar-wrapper {
  display: flex;
  flex-direction: column;
}
.media__library .media__item {
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  padding: 0px !important;
}

.media__library .media-header .searchbar > i {
  font-size: 1.5em;
  color: var(--color-active);
}

.media__library .media-header .searchbar > input.search-input {
  border: none;
  padding-left: 1rem;
  background-color: transparent;
}

.media__library .media-header .searchbar > input.search-input::placeholder {
  color: var(--color-secondary);
}

.media__library .media-header .searchbar > input.search-input:focus {
  outline: none;
}

.media__library .btn-primary {
  border: none;
  padding: 0rem 1.5rem;
  text-align: center;
  color: #fff;
  background-color: var(--color-primary);
  border-radius: 5px;
}

.media__library .ant-btn-primary {
  background: var(--color-primary);
}

.media__library .ant-btn-primary:hover {
  background: var(--color-primary);
  opacity: 0.8;
}
.media__library .ant-btn-primary:focus {
  background: var(--color-primary);
}

.media__library .media-subheader {
  display: flex;
  justify-content: flex-end;
}

.media__library .media-subheader .media-button-group > button.active {
  background-color: var(--color-active);
}

.media__library .media-subheader .media-button-group > button {
  border: none;
  background-color: var(--color-secondary);
  color: #fff;
  padding: 0.25rem 0.8rem;
  cursor: pointer;
}

.media__library .media-subheader .media-button-group > button:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.media__library .media-subheader .media-button-group > button:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.media__library .media__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
}

.media__library .media__content .media-card {
  width: 25%;
  height: auto;
  object-fit: cover;
  object-position: top;
  border-radius: 5px;
  margin-bottom: 30px;
  padding: auto;
  border: 1px solid var(--color-stroke);
}

.media__library .media__content .media__wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
}

.media__library .media__content .media__wrapper .media__item {
  width: 275px;
}

.media__library .media__content .media__wrapper .media__item .media__item-img {
  background-position: center;
  background-size: cover;
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.media__library .media__content .btn {
  width: max-content;
  display: flex;
  align-self: flex-end;
  border-radius: 5px;
}

.media__library
  .media__content
  .media__wrapper
  .media__item
  .media__item-actions {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  z-index: 30;
}

.media__library
  .media__content
  .media__wrapper
  .media__item
  .media__item-actions
  .media__item-check {
  background-color: var(--color-primary);
  color: #fff;
  height: 30px;
  width: 30px;
  border-left: 1px solid #fff;
  padding: 0;
  margin: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.media__library
  .media__content
  .media__wrapper
  .media__item
  .media__item-actions
  .media__item-check
  .media__check-logo {
  width: 15px;
  height: 15px;
}

.media__library
  .media__content
  .media__wrapper
  .media__item
  .media__item-actions
  .media__preview {
  padding: 0.25rem 0.5rem;
  outline: none;
  border: none;
  cursor: pointer;
  border-bottom-left-radius: 10px;
  background-color: var(--color-primary);
  color: #fff;
}

.media__library
  .media__content
  .media__wrapper
  .media__item
  .media__item-actions
  .media__item-delete {
  padding: 0.25rem 0.5rem;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: var(--color-primary);
  color: #fff;
  border-left: 1px solid #fff;
}

.media__library .media__content .media__item {
  position: relative;
  height: fit-content;
  height: 300px;
  width: 100%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--color-stroke);
  white-space: pre-wrap !important;
  overflow: hidden;
}

.media__library .media__content .media__wrapper .ant-tag-checkable {
  appearance: none;
  background: none;
}

.media__library .media__content .media__wrapper .ant-tag-checkable-checked {
  background: none;
}

.media__library .media__content .img-overlay {
  position: absolute;
  width: 100%;
  height: 80%;
  bottom: 0px !important;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.56) 100%
  );
}

.media__library .media__content .video-js {
  background-position: center;
  background-size: cover;
  object-fit: contain;
  width: 100%;
  height: 100%;
}

/* .media__library .media__content .media__item {
  width: 24%;
  height: auto;
  object-fit: cover;
  object-position: top;
  border-radius: 5px;
  margin-bottom: 30px;
  flex-direction: column;
  padding: 10px;
  text-align: center;
  border: 1px solid var(--color-stroke);
  word-break: break-all;
  white-space: pre-wrap !important;
  overflow-wrap: break-word;
} */
.media__library
  .media__content
  .media__wrapper
  .media__item
  .media__item-description {
  position: absolute;
  left: 0;
  bottom: 0;
  color: #fff;
  padding: 10px;
  font-size: 14px;
  line-height: normal;
  width: 100%;
  z-index: 50;
  background: linear-gradient(180deg, transparent -52.78%, rgba(0, 0, 0, 0.56));
}

.media__library
  .media__content
  .media__wrapper
  .media__item
  .media__item-description
  span {
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  display: block;
  white-space: pre-wrap;
}

.media__library
  .media__content
  .media__wrapper
  .media__item
  .media__item-description
  p {
  font-size: 13px;
  font-weight: 500;
  line-height: 19px;
  white-space: pre-wrap;
}
/* .media__library .media__content .media__wrapper .media__item .text-item {
  display: flex;
  padding: auto;
  word-wrap: break-word; 
  overflow-wrap: break-word; 
  width: 100%;
  height: 100%;
  flex-direction: column;
  word-wrap: break-word;
  overflow-wrap: break-word;
} */

.media__library .media-select {
  display: flex;
  flex-direction: row;
  padding: auto;
  justify-content: space-between;
}

.media__library .media-filter {
  display: flex;
  flex-direction: row;
  padding: auto;
  justify-content: flex-start;
}

.media__library .media__content .media-card:hover .media-overlay {
  opacity: 1;
}

/* .media__library .media__content .media-card:not(:last-child) {
  margin-right: 1rem;
} */

.media__library .media__content .media-card > img,
.media__library .media__content .media-card > video {
  width: 100%;
  height: auto;
  object-fit: contain;
  object-position: top;
  border-radius: 5px;
  border: 1px solid var(--color-stroke);
}

.media__library .media-overlay {
  position: absolute;
  background-color: #fff;
  width: 95%;
  height: auto;
  flex-direction: column;
  justify-content: flex-end;
  padding: 1rem;
  display: flex;
  transition: all 0.3s ease-in-out;
  bottom: 16px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 1px solid var(--color-stroke);
}

.media__library .media-overlay h3 {
  color: var(--color-primary);
  font-size: 1.2em;
}

.media__library .media-overlay p {
  color: var(--color-navbar);
}

.media__library .media-overlay .media-overlay-button-group {
  display: flex;
  justify-content: flex-end;
  z-index: 9;
}

.media__library .media-overlay .media-overlay-button-group > button,
.media__library .media-overlay .media-overlay-button-group > a {
  cursor: pointer;
  border: none;
  background-color: #fff;
  color: var(--color-secondary);
  padding: 0.25rem 0.5rem;
  border-radius: 5px;
  display: inline-block;
  font-size: 1.2em;
}

.media__library
  .media-overlay
  .media-overlay-button-group
  > button:not(:last-child),
.media__library
  .media-overlay
  .media-overlay-button-group
  > a:not(:last-child) {
  margin-right: 0.5rem;
}

.media__library .media-pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
}
.media__library .media-button {
  display: flex;
  flex: auto;
  justify-content: flex-end;
  margin-top: 2rem;
}

.media__library .empty-media-state {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40vh;
}

.media-form .upload-drag-and-drop {
  background-color: #fff;
  padding: 1.5rem;
  width: 140px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 2px dashed var(--color-secondary);
  border-radius: 10px;
  cursor: pointer;
}

.media-form .upload-drag-and-drop > i {
  font-size: 1.5em;
  margin-bottom: 1rem;
  display: inline-block;
  color: var(--color-secondary);
}

.media-form .upload-drag-and-drop > p {
  margin: 0px;
  padding: 0px;
  font-weight: 500;
  color: var(--color-secondary);
}

.media-form .upload-drag-and-drop:hover,
.media-form .upload-drag-and-drop:hover > i,
.media-form .upload-drag-and-drop:hover > p,
.media-form .upload-drag-and-drop.active,
.media-form .upload-drag-and-drop.active > i,
.media-form .upload-drag-and-drop.active > p {
  border-color: var(--color-active);
  color: var(--color-active);
}

.img-label-wrapper {
  position: absolute;
  left: 0;
  width: auto;
  display: flex;
}

.img-label {
  background-color: var(--color-primary);
  color: #fff;
  padding: 0.25rem 0.5rem;
}

.img-label-2 {
  background-color: #16a085;
  color: #fff;
  padding: 0.25rem 0.5rem;
  border-radius: 0px 0px 6px 0px;
}

.media__library > .media-header-arrival {
  display: flex;
  flex-direction: column;
  padding: 0px 1rem;
}

.media__library > .media-header-arrival > .media-header-input {
  margin-bottom: 1.5rem;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
}

.media__library
  > .media-header-arrival
  > .media-header-input
  > .media__button-left {
  display: flex;
  column-gap: 20px;
}

.media__library > .media-header-arrival > .media-header-input .btn-danger {
  border-radius: 5px;
}

.media__library
  > .media-header-arrival
  > .media-header-input
  > .media-header-left {
  display: flex;
  flex: 0.5;
}

.media__library
  > .media-header-arrival
  > .media-header-input
  > .media-header-right {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.media__library
  > .media-header-arrival
  > .media-header-input
  .media-form-group:not(:last-child) {
  margin-right: 1.5rem;
}

.media__library
  > .media-header-arrival
  > .media-header-input
  .media-form-group
  > label {
  color: var(--color-primary);
  margin-bottom: 0.25rem;
  display: block;
}

.media__library
  > .media-header-arrival
  > .media-header-input
  .media-form-group
  > .searchbar {
  width: 100%;
  background-color: #fff;
}

.media__library
  > .media-header-arrival
  > .media-header-input
  .media-form-group {
  width: 225px;
}
