.base-layout {
  display: flex;
  min-height: 100vh;
  overflow-y: auto;
}

.base-layout .sidebar {
  background-color: var(--color-background);
  padding: 2% calc(1% + 5px) 1% 1%;
  border-right: 1px solid var(--color-stroke);
  z-index: 4;
  width: 280px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  height: 100vh;
  overflow: hidden;
  overflow-y: hidden;
  position: fixed;
}

.base-layout .sidebar:hover {
  overflow-y: scroll;
  padding: 2% 1% 1% 1%;
  transition: overflow ease-in-out 750ms;
}

.base-layout .sidebar::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.base-layout .sidebar::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.base-layout .sidebar::-webkit-scrollbar-thumb {
  background: var(--color-primary);
  border-radius: 4px;
}

/* Handle on hover */
/* .base-layout .sidebar::-webkit-scrollbar-thumb:hover {
  background: #555;
} */

.base-layout .sidebar > .sidebar-top > img {
  margin: auto;
  max-width: 80px;
  display: flex;
}

.base-layout .sidebar > .sidebar-top > ul {
  margin-top: 2.5rem;
  display: flex;
  flex-direction: column;
  padding: 0px;
  list-style: none;
}

.base-layout .sidebar > .sidebar-top > ul > li > a,
.base-layout .sidebar > .sidebar-top > ul > li > span {
  font-size: 1em;
  /* color: var(--color-secondary); */
  color: var(--color-navbar);
  margin: 1rem 0px;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  display: inline-block;
  font-weight: 500;
  width: 100%;
}

.base-layout .sidebar-bottom > ul.sidebar-bottom-list {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  margin: 0;
}

.base-layout .sidebar-bottom > ul.sidebar-bottom-list > .sidebar-bottom-title {
  color: var(--color-active);
  font-weight: 600;
  display: inline-block;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.base-layout
  .sidebar-bottom
  > ul.sidebar-bottom-list
  > .sidebar-bottom-subtitle {
  color: var(--color-black);
}

.base-layout .sidebar-bottom > ul.sidebar-bottom-list > .sidebar-bottom-button {
  margin-top: 2rem;
  background-color: var(--color-active);
  padding: 0.5rem 1rem;
  width: 100%;
  border-radius: 5px;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
}

.base-layout
  .sidebar-bottom
  > ul.sidebar-bottom-list
  > .sidebar-bottom-button:hover {
  opacity: 0.8;
}

.base-layout
  .sidebar-bottom
  > ul.sidebar-bottom-list
  > .sidebar-bottom-button
  > i {
  margin-right: 0.5rem;
}

.base-layout .sidebar > .sidebar-top > ul > li > a > span,
.base-layout .sidebar > .sidebar-top > ul > li > a > div > div > span {
  font-family: 'Poppins', sans-serif;
  margin-left: 1rem;
}

.base-layout .sidebar > .sidebar-top > ul > li > a > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.base-layout .sidebar > .sidebar-top > ul > li > a:hover,
.base-layout .sidebar > .sidebar-top > ul > li > span:hover {
  color: #fff;
}

.base-layout .sidebar > .sidebar-top > ul > li.active > a,
.base-layout .sidebar > .sidebar-top > ul > li.active > span,
.base-layout .sidebar > .sidebar-top > ul > li > a:hover,
.base-layout .sidebar > .sidebar-top > ul > li > span:hover {
  background-color: var(--color-stroke);
  color: var(--color-primary);
}

.base-layout .sidebar-collapsed {
  padding: 2%;
  font-family: 'Poppins', sans-serif;
  border-right: 1px solid var(--color-stroke);
  position: relative;
  transition: all 100ms ease-in-out;
  margin-left: -300px;
  background-color: #fff;
  width: auto;
}

.base-layout .sidebar-collapsed > .toggle-dropdown {
  width: 200px;
}

.base-layout
  .sidebar-collapsed
  .toggle-dropdown
  > li
  > .organization-list-title {
  font-size: 1.2em !important;
}

.base-layout .sidebar-collapsed > .toggle-dropdown > li > span,
.base-layout .sidebar-collapsed > .toggle-dropdown > li > a > span {
  color: var(--color-active);
  font-weight: 500;
  cursor: pointer;
  width: 100%;
  display: inline-block;
  padding: 0.5rem 0.8rem;
  border-radius: 20px;
  transition: all 0.2s ease-in-out;
}

.base-layout .sidebar-collapsed > .toggle-dropdown > li > span:hover,
.base-layout .sidebar-collapsed > .toggle-dropdown > li > a > span:hover {
  background-color: var(--color-stroke);
  color: var(--color-background);
}
.base-layout
  .sidebar-collapsed
  > .toggle-dropdown
  > li
  > .organization-list-title {
  background-color: transparent;
  pointer-events: none;
  cursor: default;
}

.base-layout .sidebar-collapsed > .toggle-dropdown > .divider {
  width: 100%;
  border-bottom: 1px solid var(--color-stroke);
  margin: 20px 0px;
}

.base-layout .sidebar-collapsed > .toggle-dropdown > li.has-dropdown > span {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  cursor: pointer;
}

.base-layout
  .sidebar-collapsed
  > .toggle-dropdown
  > li.has-dropdown
  > span::after {
  content: '\e92e';
  font-family: 'Feather';
  transition: all 100ms ease-in-out;
  transform: rotate(-90deg);
}

.base-layout
  .sidebar-collapsed
  > .toggle-dropdown
  > li.has-dropdown.active
  > span::after {
  transform: rotate(0deg);
}

.base-layout
  .sidebar-collapsed
  > .toggle-dropdown
  > li.has-dropdown
  > ul.collapsable {
  margin-left: 1rem;
  transition: all 100ms ease-in-out;
  opacity: 0;
  width: 180px;
  margin-bottom: 1.5rem;
}

.base-layout
  .sidebar-collapsed
  > .toggle-dropdown
  > li.has-dropdown
  > ul.collapsable
  > li
  > a {
  margin-bottom: 1rem;
  color: hsl(0, 0%, 35%);
  font-weight: 500;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  padding-left: 10px;
}

.base-layout
  .sidebar-collapsed
  > .toggle-dropdown
  > li.has-dropdown
  > ul.collapsable
  > li:hover {
  color: var(--color-active);
}

.base-layout .sidebar-collapsed .close {
  position: absolute;
  right: -13px;
  z-index: 3;
  background-color: #fff;
  border: 1px solid var(--color-stroke);
  padding: 2px 6px;
  border-radius: 50%;
  cursor: pointer;
}

.base-layout .container {
  width: var(--screen-width);
  margin-left: 279px;
  transition: margin 0.3s ease-in-out;
}

.base-layout .container-collapsed {
  margin-left: 0px !important;
}

.container {
  z-index: 2;
  display: flex;
  flex-direction: column;
}

/* navbar */

.base-layout .navbar {
  display: flex;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid var(--color-stroke);
  /* padding: 1% 2%; */
  padding: 0 1%;
  height: 4.25rem;
  justify-content: flex-end;
  font-family: 'Poppins', sans-serif;
}

.base-layout .navbar .profile-container {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}

.base-layout .navbar .profile-container .profile-name {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right !important;
}

.base-layout .navbar .profile-container .profile-name h3 {
  font-size: 1em;
  font-weight: 500;
  color: var(--color-active);
  margin: 0px;
}

.base-layout .navbar .profile-container .profile-name p {
  margin: 0px;
  color: var(--color-secondary);
  font-weight: 400;
}

.base-layout .navbar .profile-container .profile-picture > img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.base-layout .navbar .profile-container .profile-setting {
  display: flex;
}

.base-layout .navbar .profile-container .profile-setting i {
  font-size: 2em;
  color: var(--color-active);
  cursor: pointer;
}

.base-layout .navbar .profile-dropdown {
  display: flex;
  position: absolute;
  padding: 1%;
  width: 200px !important;
  background-color: #fff;
  border: 1px solid var(--color-stroke);
  border-radius: 5px;
  right: 5px;
  transition: all 80ms ease-in-out;
  opacity: 0;
}

.non-admin {
  bottom: -100px;
}

.admin {
  bottom: -140px;
}

.base-layout .navbar .profile-dropdown > ul {
  margin: 0px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.base-layout .navbar .profile-dropdown > ul > li:not(:last-child) {
  margin-bottom: 0.5rem;
  border-bottom: 1px solid var(--color-stroke);
  padding-bottom: 0.5rem;
}

.base-layout .navbar .profile-dropdown > ul > li,
.base-layout .navbar .profile-dropdown > ul > li > a {
  color: var(--color-black);
  cursor: pointer;
}

.base-layout .navbar .profile-dropdown > ul > li:hover,
.base-layout .navbar .profile-dropdown > ul > li:hover > a {
  color: var(--color-active);
}

.base-layout .navbar .profile-dropdown > ul > li > i,
.base-layout .navbar .profile-dropdown > ul > li > a > i {
  margin-right: 0.5rem;
}

.base-layout .navbar .profile-dropdown .triangle {
  position: absolute;
  top: -10px;
  right: 30px;
  z-index: -1;
  transition: all 100ms ease-in-out;
  opacity: 0;
}

/* content */

.base-layout .content {
  background-color: var(--color-background);
  display: flex;
  height: 100%;
  width: 100%;
  /* min-height: 100vh; */
  margin: 0 auto;
}

/* @media (max-width: 1440px) {
  .base-layout .content {
    width: 968px;
  }
} */

@media (max-width: 1366px) {
  .base-layout .content {
    /* width: 768px; */
    width: 100%;
  }
}

@media (min-height: 960px) {
  .base-layout .content {
    min-height: calc(100vh - 7.75rem);
  }
}

/* footer */
.base-layout .footer {
  font-family: 'Poppins', sans-serif;
  display: flex;
  height: 3.5rem;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: var(--color-secondary);
  padding: 1% 2% !important;
}

.base-layout .footer > span {
  margin: 0px;
  display: block;
}

/* replace antd style */
.ant-modal-header,
.ant-modal-body,
.ant-modal-footer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.ant-modal-body {
  display: flex;
  flex-direction: column;
}

.ant-modal-body .ant-form-item {
  display: flex;
  justify-content: center;
  padding: 0 3rem;
}

.ant-form {
  width: 100%;
}

.map-form {
  width: 100%;
}

@media (min-width: 1200px) {
  .playlist .playlist__card {
    grid-template-columns: repeat(2, 1fr);
  }
}


@media (min-width: 1400px) {
  .playlist .playlist__card {
    grid-template-columns: repeat(3, 1fr);
  }
}
