.full-layout {
  display: flex;
  min-height: 100vh;
}

.full-layout .left-content {
  position: relative;
  display: flex;
  background-image: url('../../assets/Sidebar.svg');
  background-repeat: no-repeat;
  background-size: cover;
  padding: 5% 2%;
  width: 24rem;
}

.full-layout .left-content img {
  width: auto;
  height: 40px;
}

.full-layout .right-content {
  display: flex;
  padding: 5% 10% 5% 5%;
  justify-content: center;
}
