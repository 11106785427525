.siteinfo {
  padding: 2rem;
  font-family: 'Poppins', sans-serif;
  width: 100%;
}

.siteinfo .bottom-nav {
  display: flex;
  justify-content: flex-end;
}

.ant-dropdown-menu-title-content {
  font-family: 'Poppins', sans-serif !important;
  color: var(--color-active) !important;
}

.siteinfo .siteinfo-nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.siteinfo .siteinfo-nav .button-primary {
  background-color: var(--color-primary);
  border: none;
  color: #fff;
  padding: 0.5rem 1rem;
  align-self: center;
  border-radius: 5px;
  cursor: pointer;
}

/* .siteinfo h3.title {
  font-size: 1.2em;
  color: var(--color-active);
  margin-bottom: 2.5rem;
} */
.siteinfo .siteinfo-nav {
  margin-bottom: 2.5rem;
}

.siteinfo .siteinfo-nav .title {
  font-size: 1.2em;
  color: var(--color-active);
}

.siteinfo .siteinfo-nav span {
  font-size: 1em;
  color: var(--color-active);
}

.siteinfo .ant-tabs > .ant-tabs-nav > .ant-tabs-nav-wrap {
  width: 100% !important;
  display: flex;
}

.siteinfo .ant-tabs-nav-wrap > .ant-tabs-nav-list {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.siteinfo .ant-tabs-nav-wrap > .ant-tabs-nav-list > .ant-tabs-tab {
  /* color: var(--color-secondary); */
  /* margin-right: 6rem; */
  /* color: --var(--color-light-grey); */
  color: var(--color-grey);
  width: 100%;
  margin-right: 0.1rem;
  justify-content: center;
}

.siteinfo
  .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab.ant-tabs-tab-active
  > .ant-tabs-tab-btn,
.siteinfo .ant-tabs-nav-wrap > .ant-tabs-nav-list > .ant-tabs-tab:hover {
  color: var(--color-active) !important;
}

.siteinfo .ant-tabs-ink-bar {
  background-color: var(--color-active);
  /* min-width: 180px !important; */
  width: 100%;
}

.siteinfo .tab-content {
  margin: 0.5rem 0px;
}

.playlist-menu {
  display: flex;
}

.playlist-menu .menu-tab-side {
  width: 200px;
  min-height: 100vh;
  border-right: 1px solid var(--color-stroke);
  margin-right: 1rem;
}

.playlist-menu .menu-tab-side > ul {
  margin: 0px;
  list-style: none;
}

.playlist-menu .menu-tab-side > ul > li > i {
  margin-right: 0.5rem;
}

.playlist-menu .menu-tab-side > ul > li {
  color: var(--color-secondary);
  padding: 0.5rem 0px;
  cursor: pointer;
  position: relative;
}

.playlist-menu .menu-tab-side > ul > li:hover,
.playlist-menu .menu-tab-side > ul > li.active {
  color: var(--color-primary);
  font-weight: 500;
  transition: all 3ms ease-in-out;
}

.playlist-menu .menu-tab-side > ul > li:hover::after,
.playlist-menu .menu-tab-side > ul > li.active::after {
  content: '';
  border-right: 3px solid var(--color-primary);
  position: absolute;
  right: -2px;
  top: 0px;
  height: 100%;
}
