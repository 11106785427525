.login {
  font-family: 'Rubik', sans-serif;
  width: 30rem;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

.login > span {
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--color-blue-4);
  display: block;
  margin-bottom: 4rem;
}

.login h1 {
  color: var(--color-blue-1);
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 0.375rem;
}

.login h2 {
  color: var(--color-active);
  font-size: 1.2em;
}

.login > p {
  color: var(--color-text-grey);
  margin-bottom: 2rem;
}

.login .ant-alert-error {
  background-color: HSL(0, 84%, 95%) !important;
}

.login .ant-alert-message {
  color: HSL(0, 83%, 55%) !important;
}

.login .form-wrapper {
  margin: 0.5rem 0 2.5rem 0;
}

.login .form-wrapper > .form {
  position: relative;
  border: 1px solid #e0e0e0 !important;
  /* border-radius: 2px; */
}

.login .form-wrapper > .error-login {
  border: 1px solid HSL(0, 83%, 55%) !important;
}

.login .form-wrapper > .form:first-child {
  border-bottom: none !important;
}

.login .form-wrapper > .form .form-label {
  position: absolute;
  left: 4.1rem;
  top: 0.5rem;
  z-index: 9999;
  pointer-events: none;
  transform: translateY(1.37rem);
  transition: 0.3s all ease;
  color: var(--color-text-grey);
}

.login
  .form-wrapper
  > .form
  .ant-input-affix-wrapper
  > input.ant-input:focus
  ~ .ant-input-suffix
  > .form-label,
.login
  .form-wrapper
  > .form
  .ant-input-affix-wrapper
  > input.ant-input:not(:focus):valid
  ~ .ant-input-suffix
  > .form-label {
  transform: translateY(0) !important;
}

.login .form-wrapper > .form input.ant-input {
  border-radius: 0px !important;
  padding: 1.875rem 1.5675rem 1.875rem 1rem;
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  background-color: #fff;
  border-radius: 2px !important;
  color: #000000;
}

.login .form-wrapper > .form input.ant-input::placeholder {
  color: var(--color-text-grey);
}

.login .form-wrapper > .form .ant-input-affix-wrapper {
  position: relative;
  padding: 0 1.98rem 0 1.5rem;
  font-size: 1rem !important;
  border-radius: 0 !important;
  border: none;
  border-radius: 2px !important;
  overflow: hidden;

  box-shadow: 0px 12px 40px rgba(224, 224, 224, 0.2) !important;
}

.login .form-wrapper > .form .ant-input-affix-wrapper-focused {
  outline: none !important;
}

.login .form-wrapper > .form .ant-input-affix-wrapper::after {
  position: absolute;
  content: '';
  height: 100%;
  width: 0.375rem;
  left: 0;
  bottom: 0;
  background-color: transparent;
  transition: 0.5s all ease;
}

.login .form-wrapper > .form .ant-input-affix-wrapper-focused::after {
  background-color: hsla(191, 89%, 64%, 1);
}

.login .form-wrapper > .form .ant-input-affix-wrapper > input {
  border: none !important;
}

.login .form-wrapper > .form .ant-input-group-addon {
  background-color: #fff;
  color: hsla(0, 0%, 48%, 1);
  border: none !important;
  font-size: 1.079375rem !important;
  padding-right: 1.375rem !important;
}

.login .form-wrapper > .form:last-child {
  margin-top: 4rem;
}

.login .form-wrapper > .more-actions > .flex {
  margin-top: 1.5rem;
  display: flex;
  justify-content: flex-end;
}

.login .form-wrapper > .more-actions > .flex > .ant-checkbox-wrapper > span {
  font-size: 1rem;
  font-weight: 400;
  color: hsla(0, 0%, 48%, 1);
}

.login .form-wrapper > .more-actions > .flex > a > span {
  font-size: 1rem;
  font-weight: 400;
  color: #53d7f5;
  cursor: pointer;
}

.login .form-wrapper > .form > .button {
  display: block;
  color: #fff;
  font-size: 1rem;
  padding: 1rem 0;
  width: 100%;
  height: 100%;
  font-weight: bold;
  background: #53d7f5;
  box-shadow: 0px 12px 12px rgba(83, 215, 245, 0.1);
  border-radius: 2px;
  border: none;
}
