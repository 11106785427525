.logs {
  font-family: 'Poppins', sans-serif;
  padding: 2% 3%;
  width: 100%;
}

.logs .logs-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.logs .logs-menu {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 1rem;
}

.logs .logs-dropdown {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 50px;
  flex: 1;
}

.logs .logs-download {
  display: flex;
}

.logs .logs-dropdown span {
  color: var(--color-active);
  font-size: 1em;
  font-weight: 500;
  margin-right: 0.5rem;
}

.logs .ant-select {
  display: flex;
  width: 30% !important;
  margin-right: 0.5em;
  border-radius: 0.5rem !important;
  color: var(--color-active);
}

.logs .ant-select-selector {
  background-color: transparent !important;
}

.logs .search-bar {
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  border: 1px solid var(--color-secondary);
  padding: 0.3rem 0.7rem;
  margin-right: 1rem;
  width: 18%;
}
.logs .dropdown-tree {
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  border: 1px solid var(--color-secondary);
  padding: 0.3rem 0.7rem;
  margin-right: 1rem;
  width: 18%;
}

.logs .search-bar i {
  font-size: 1.5em;
  color: var(--color-active);
}

.logs .search-bar input {
  outline: none;
  border: none;
  background-color: transparent;
  padding-left: 1rem;
  color: var(--color-active);
}

.logs .search-bar input::placeholder {
  color: var(--color-secondary) !important;
}

.logs .ant-pagination-options-size-changer.ant-select {
  width: 8rem !important;
}

.logs table th {
  background-color: var(--color-navbar) !important;
  color: var(--color-black);
}

.logs table .btn {
  color: hsla(0, 0%, 31%, 1);
  background-color: transparent;
  outline: none;
  border: none;
}

.logs table tr {
  font-size: 0.8rem;
}

.logs table td.ant-table-cell {
  padding: 0.5rem;
}

.logs table .btn i {
  margin-right: 0.2rem;
}

.logs table .btn:hover {
  cursor: pointer;
}

.logs table .btn--edit .btn--edit:hover {
  color: var(--color-primary);
}
.logs table .btn--access .btn--access:hover {
  color: green;
}
.logs table .btn--delete .btn--delete:hover {
  color: red;
}

.logs .logs-limit {
  margin-bottom: 1rem;
}

.logs .logs-label {
}

.logs .logs-input {
}

.logs .logs-date-picker {
  width: auto;
  height: max-content;
}

.logs .logs-btn button {
  background: var(--color-active);
  border-radius: 5px;
}

.logs .logs-btn button:hover {
  opacity: 0.8;
}

.logs .logs-search {
  display: flex;
  column-gap: 12px;
}
