.playlist {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  width: 100%;
}

.media__database-modal {
  width: 100% !important;
  min-height: 100% !important;
  top: 0 !important;
  padding: 8px 0 !important;
}

.media__database-modal
  .ant-modal-content
  .ant-modal-body
  .media__database-arrival
  .media__database-arrival-right
  .ant-form
  .ant-form-item {
  padding: 0;
}

.media__database-modal
  .ant-modal-content
  .ant-modal-body
  .media__database-arrival
  .media__database-arrival-right
  .ant-form
  .ant-form-item
  .ant-btn {
  background-color: var(--color-primary);
  border-radius: 10px;
}

.media__database-modal
  .ant-modal-content
  .ant-modal-body
  .media__database-arrival
  .media__database-arrival-header
  .media__database-delete {
  border-radius: 10px;
  background-color: #ff4d4f;
  color: #fff;
}

.media__database-modal
  .ant-modal-content
  .ant-modal-body
  .media__database-arrival
  .media__database-arrival-header
  .media__database-delete:hover {
  background-color: #ff7875;
}

.media__database-modal
  .ant-modal-content
  .ant-modal-body
  .media__database-arrival
  .media__database-arrival-header
  .media__database-add {
  background-color: var(--color-primary);
  border-radius: 10px;
  color: #fff;
}

.media__database-modal
  .ant-modal-content
  .ant-modal-body
  .media__database-arrival
  .media__database-arrival-header
  .media__database-add:hover {
  opacity: 0.8;
}

.media__database-modal .ant-modal-content .ant-modal-body {
  padding: 0;
}

.media__database-modal .ant-modal-content {
  border-radius: 10px 10px 0px 0px !important;
}

.media__database-modal .ant-modal-content .ant-modal-header {
  background-color: var(--color-primary);
  justify-content: flex-start;
  border-radius: 10px 10px 0px 0px !important;
}

.media__database-modal .ant-modal-content .ant-modal-close {
  color: #fff !important;
}

.media__database-modal .ant-modal-content .ant-modal-header .ant-modal-title {
  color: #fff;
  font-size: 18px;
  font-family: Poppins, sans-serif;
}

.media__database-modal
  .ant-modal-content
  .ant-modal-body
  .media__database-arrival {
  display: flex;
  width: 100%;
  min-height: calc(100vh - 71px);
}

.media__database-modal
  .ant-modal-content
  .ant-modal-body
  .media__database-arrival-left {
  width: 70%;
  padding: 65px 35px;
}

.media__database-modal
  .ant-modal-content
  .ant-modal-body
  .media__database-arrival-right {
  width: 30%;
  border-left: 2px solid #dadada;
  padding: 65px 35px;
}

.media__database-modal
  .ant-modal-content
  .ant-modal-body
  .media__database-arrival-left
  .media__database-arrival-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.media__database-modal
  .ant-modal-content
  .ant-modal-body
  .media__database-arrival-left
  .media__database-arrival-header
  .media__database-search {
  display: flex;
  column-gap: 12px;
}

.media__database-modal
  .ant-modal-content
  .ant-modal-body
  .media__database-arrival-left
  .media__database-arrival-header
  .media__database-actions {
  display: flex;
  column-gap: 12px;
}

.media__database-modal
  .ant-modal-content
  .ant-modal-body
  .media__database-arrival-left
  .media__wrapper
  .media__item {
  width: 200px;
  position: relative;
  height: 200px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid var(--color-stroke);
}

.media__database-modal
  .ant-modal-content
  .ant-modal-body
  .media__database-arrival-left
  .media__wrapper
  .media__item
  .ant-tag {
  margin: 0;
  padding: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.media__database-modal
  .ant-modal-content
  .ant-modal-body
  .media__database-arrival-left
  .media__wrapper
  .media__item
  ::selection {
  background: none !important;
  color: none !important;
}

.media__database-modal
  .ant-modal-content
  .ant-modal-body
  .media__database-arrival-left
  .media__wrapper
  .media__item
  .media__item-img {
  background-position: center;
  background-size: cover;
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.media__database-modal
  .ant-modal-content
  .ant-modal-body
  .media__database-arrival-left
  .media__wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  margin-bottom: 1rem;
}

.media__database-modal
  .ant-modal-content
  .ant-modal-body
  .media__database-arrival-left
  .media__wrapper
  .media__item
  .media__item-description {
  position: absolute;
  left: 0;
  bottom: 0;
  color: #fff;
  padding: 10px;
  font-size: 14px;
  line-height: normal;
  z-index: 30;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) -52.78%,
    rgba(0, 0, 0, 0.56) 100%
  );
}

.media__database-modal
  .ant-modal-content
  .ant-modal-body
  .media__database-arrival-left
  .media__wrapper
  .media__item
  .media__item-description
  span {
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  display: block;
}

.media__database-modal
  .ant-modal-content
  .ant-modal-body
  .media__database-arrival-left
  .media__wrapper
  .media__item
  .media__item-description
  p {
  font-size: 13px;
  font-weight: 500;
  line-height: 19px;
}

.media__database-modal
  .ant-modal-content
  .ant-modal-body
  .media__database-arrival-left
  .media__database-arrival-playlist-name {
  font-family: Poppins, sans-serif;
  font-size: 18px;
  margin-bottom: 42px;
  color: var(--color-primary);
}

.media__database-modal
  .ant-modal-content
  .ant-modal-body
  .media__database-arrival-right
  .media__database-arrival-upload-title {
  font-family: Poppins, sans-serif;
  font-size: 18px;
  margin-bottom: 42px;
  color: var(--color-primary);
}

.media__database-modal
  .ant-modal-content
  .ant-modal-body
  .media__database-arrival-right
  .btn-remove {
  position: absolute;
  left: -5px;
  top: -5px;
  background-color: var(--color-danger);
  border: none;
  color: #fff;
  cursor: pointer;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.media__database-modal
  .ant-modal-content
  .ant-modal-body
  .media__database-arrival-right
  .ant-upload {
  width: 100%;
}

.media__database-modal
  .ant-modal-content
  .ant-modal-body
  .media__database-arrival-right
  .upload-drag-and-drop {
  background-color: #fff;
  padding: 1.5rem;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 2px dashed var(--color-secondary);
  border-radius: 10px;
  cursor: pointer;
}

.media__database-modal
  .ant-modal-content
  .ant-modal-body
  .media__database-arrival-right
  .upload-drag-and-drop
  > i {
  font-size: 1.5em;
  margin-bottom: 1rem;
  display: inline-block;
  color: var(--color-secondary);
}

.media__database-modal
  .ant-modal-content
  .ant-modal-body
  .media__database-arrival-right
  .upload-drag-and-drop
  > p {
  margin: 0px;
  padding: 0px;
  font-weight: 500;
  color: var(--color-secondary);
}

.media__database-modal
  .ant-modal-content
  .ant-modal-body
  .media__database-arrival-right
  .upload-drag-and-drop:hover,
.media__database-modal
  .ant-modal-content
  .ant-modal-body
  .media__database-arrival-right
  .upload-drag-and-drop:hover
  > i,
.media__database-modal
  .ant-modal-content
  .ant-modal-body
  .media__database-arrival-right
  .upload-drag-and-drop:hover
  > p,
.media__database-modal
  .ant-modal-content
  .ant-modal-body
  .media__database-arrival-right
  .upload-drag-and-drop.active,
.media__database-modal
  .ant-modal-content
  .ant-modal-body
  .media__database-arrival-right
  .upload-drag-and-drop.active
  > i,
.media__database-modal
  .ant-modal-content
  .ant-modal-body
  .media__database-arrival-right
  .upload-drag-and-drop.active
  > p {
  border-color: var(--color-active);
  color: var(--color-active);
}

.media__database-modal
  .ant-modal-content
  .ant-modal-body
  .media__database-arrival-right
  .btn-wrapper {
  display: flex;
  width: 100%;
  padding-left: 90px !important;
}

/* .media__database-modal
  .ant-modal-content
  .ant-modal-body
  .media__database-arrival-right
  .ant-form-item-control-input
  .ant-form-item-control-input-content {
  display: flex !important;
  justify-content: center;
  column-gap: 12px !important;
} */

.media__database-modal
  .ant-modal-content
  .ant-modal-body
  .media__database-arrival-right
  .cancel-upload-btn {
  background-color: var(--color-danger) !important;
  margin-right: 12px;
}

.media__database-modal
  .ant-modal-content
  .ant-modal-body
  .media__database-arrival-right
  .cancel-upload-btn:hover {
  opacity: 0.8;
}

.media__database-modal
  .ant-modal-content
  .ant-modal-body
  .media__database-arrival-right
  .submit-upload-btn {
  background-color: var(--color-primary) !important;
}

.media__database-modal
  .ant-modal-content
  .ant-modal-body
  .media__database-arrival-right
  .submit-upload-btn:hover {
  opacity: 0.8;
}

/* .media__database-modal
  .ant-modal-content
  .ant-modal-body
  .media__database-arrival
  .media__database-arrival-left
  .media__wrapper
  .ant-tag {
  padding: 0 !important;
  margin: 0 !important;
  position: relative !important;
  overflow: hidden !important;
} */

.media__database-modal
  .ant-modal-content
  .ant-modal-body
  .media__database-arrival
  .media__database-arrival-left
  .media__wrapper
  .media__item
  .media__item-edit {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0.5rem 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  background: var(--color-primary);
  border-top-left-radius: 10px;
  cursor: pointer;
}

.media__database-modal
  .ant-modal-content
  .ant-modal-body
  .media__database-arrival
  .media__database-arrival-left
  .media__wrapper
  .media__item
  .media__item-edit
  svg {
}

.media__database-modal
  .ant-modal-content
  .ant-modal-body
  .media__database-arrival
  .media__database-arrival-left
  .media__wrapper
  .media__item
  .img-overlay {
  position: absolute;
  width: 100%;
  height: 80%;
  bottom: 0px !important;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.56) 100%
  );
}

.media__database-modal
  .ant-modal-content
  .ant-modal-body
  .media__database-arrival
  .media__database-arrival-left
  .media__wrapper
  .media__item
  .checked-overlay {
  position: absolute;
  z-index: 100;
  inset: 0;
  background: hsla(209, 53%, 28%, 0.58) !important;
}

.media__database-modal
  .ant-modal-content
  .ant-modal-body
  .media__database-arrival
  .media__database-arrival-left
  .ant-tag-checkable-checked {
  background: hsla(209, 53%, 28%, 0.58) !important;
}

.media__database-modal
  .ant-modal-content
  .ant-modal-body
  .media__database-arrival
  .media__database-arrival-left
  .media__wrapper
  .media__item
  .checked-overlay
  > .icon-checked {
  position: absolute;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.playlist__modal-calendar-start {
  margin-bottom: 38px;
}

.playlist__modal-duration {
  margin-top: 38px;
}

.playlist__modal-duration .playlist__modal-duration-label {
  display: block;
  margin-bottom: 14px;
}

.playlist__modal-calendar-start .ant-picker {
  width: 100%;
}

.playlist__modal-calendar-start .playlist__modal-calendar-start-label {
  display: block;
  margin-bottom: 14px;
}

.playlist__modal-calendar-end .ant-picker {
  width: 100%;
}

.playlist__modal-calendar-end .playlist__modal-calendar-end-label {
  margin-bottom: 14px;
  display: block;
}

.playlist .playlist__arrival-wrapper {
  border: 5px;
}

.playlist .playlist__arrival-wrapper .playlist__header {
  background-color: var(--color-primary);
}

.playlist .playlist__arrival-wrapper .playlist__header:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.playlist .playlist__arrival-wrapper .playlist__header:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.playlist
  .playlist__arrival-wrapper
  .playlist__header
  .ant-collapse-header:hover {
  background-color: rgba(255, 255, 255, 0.2);
  transition: 0.3s all ease;
}

.playlist .playlist__arrival-wrapper .playlist__header .ant-collapse-header {
  color: #fff;
}

.playlist
  .playlist__arrival-wrapper
  .playlist__header
  .playlist__panel
  .playlist__options {
  display: flex;
  margin-bottom: 12px;
  justify-content: flex-end;
  align-items: center;
  column-gap: 12px;
}

.playlist
  .playlist__arrival-wrapper
  .playlist__header
  .playlist__panel
  .playlist__options
  .playlist__checkbox {
  display: flex;
  column-gap: 12px;
}

.playlist
  .playlist__arrival-wrapper
  .playlist__header
  .playlist__panel
  .playlist__action-buttons {
  display: flex;
  column-gap: 12px;
}

.playlist
  .playlist__arrival-wrapper
  .playlist__header
  .playlist__panel
  .playlist__action-buttons
  .playlist__options
  .playlist__checkbox
  .playlist__checkbox-title {
}

.playlist
  .playlist__arrival-wrapper
  .playlist__header
  .playlist__panel
  .playlist__options
  .playlist__action-buttons
  > .playlist__add-file-btn {
  background-color: var(--color-primary);
  color: #fff;
  border-radius: 10px;
  font-family: Poppins, sans-serif;
  font-size: 13px;
  font-weight: 500;
}

.playlist
  .playlist__arrival-wrapper
  .playlist__header
  .playlist__panel
  .playlist__options
  .playlist__action-buttons
  > .playlist__add-file-btn:hover {
  opacity: 0.8;
}

.playlist
  .playlist__arrival-wrapper
  .playlist__header
  .playlist__panel
  .playlist__options
  .playlist__action-buttons
  > .playlist__set-time-btn {
  background-color: var(--color-primary);
  color: #fff;
  border-radius: 10px;
  font-family: Poppins, sans-serif;
  font-size: 13px;
  font-weight: 500;
}

.playlist
  .playlist__arrival-wrapper
  .playlist__header
  .playlist__panel
  .playlist__options
  .playlist__action-buttons
  > .playlist__set-time-btn:hover {
  opacity: 0.8;
}

.playlist
  .playlist__arrival-wrapper
  .playlist__header
  .playlist__panel
  .playlist__options
  .playlist__action-buttons
  > .playlist__delete-file-btn {
  color: #fff;
  border-radius: 10px;
  font-family: Poppins, sans-serif;
  font-size: 13px;
  font-weight: 500;
}

.playlist
  .playlist__arrival-wrapper
  .playlist__header
  .playlist__panel
  .playlist__options
  .playlist__action-buttons
  > .playlist__delete-file-btn:hover {
  opacity: 0.8;
}

.playlist .playlist__arrival-wrapper .playlist__card {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 2rem;
  padding: 0.5rem 1rem;
}

@media (min-width: 1100px) {
  .playlist .playlist__arrival-wrapper .playlist__card {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1300px) {
  .playlist .playlist__arrival-wrapper .playlist__card {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1500px) {
  .playlist .playlist__arrival-wrapper .playlist__card {
    grid-template-columns: repeat(4, 1fr);
  }
}

.playlist__card-item {
  z-index: 10;
  position: relative;
  height: 300px;
  width: 100%;
  border-radius: 5px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--color-stroke);
  cursor: default;
  align-items: center;
  justify-content: center;
}

.playlist__card-item .ant-tag {
  margin: 0;
  padding: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.playlist__card-item ::selection {
  background: none !important;
  color: none !important;
}

.playlist .playlist__arrival-wrapper .playlist__card .playlist__card-calendar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  white-space: pre-wrap;
}

.playlist
  .playlist__arrival-wrapper
  .playlist__card
  .playlist__card-calendar-text {
  font-weight: 700;
  font-size: 13px;
  line-height: 14px;
  font-family: Roboto, sans-serif;
}

.playlist
  .playlist__arrival-wrapper
  .playlist__card
  .playlist__card-calendar-wrapper {
  display: flex;
  align-items: center;
}

.playlist
  .playlist__arrival-wrapper
  .playlist__card
  .playlist__card-calendar-logo {
  margin-right: 12px;
}

.playlist
  .playlist__arrival-wrapper
  .playlist__card
  .playlist__card-calendar-edit {
}

.playlist
  .playlist__arrival-wrapper
  .playlist__card
  .playlist__card-calendar-edit-logo {
  padding: 0.25rem 0.5rem;
  border-radius: 5px;
  background-color: var(--color-primary);
  color: #fff;
  outline: none;
  border: none;
  cursor: pointer;
}

.playlist__card-item:active,
.playlist__card-item:hover {
  cursor: grab;
}

body > .playlist__card-item:hover {
  cursor: grabbing !important;
}

.playlist__card-description {
  position: absolute;
  left: 0;
  bottom: 0;
  color: #fff;
  padding: 10px;
  font-size: 14px;
  line-height: normal;
  z-index: 30;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) -52.78%,
    rgba(0, 0, 0, 0.56) 100%
  );
}

.playlist__card-description {
  background: linear-gradient(180deg, transparent -52.78%, rgba(0, 0, 0, 0.56));
}

.playlist__card-description .playlist__post-name {
  display: block;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  font-family: Roboto, sans-serif;
  margin-bottom: 8px;
}

.playlist__card-description .playlist__post-duration {
  display: block;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  font-family: Roboto, sans-serif;
  margin-bottom: 8px;
}

.playlist__card-header {
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
}

.media-actions {
  z-index: 30;
}
.ant-popover-inner-content {
  padding: 0.5rem 0.5rem !important;
}

.playlist__media-popover {
  display: flex;
  column-gap: 0.25rem;
}

/* .playlist__media-popover .playlist__media-delete,
.playlist__media-popover .playlist__media-date {
  padding: 0.25rem 0.5rem;
  border-radius: 5px;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: hsl(212, 46%, 25%);
  color: #fff;
} */

.playlist__card-item .playlist__actions .playlist__media-preview {
  padding: 0.25rem 0.5rem;
  outline: none;
  border: none;
  cursor: pointer;
  border-bottom-left-radius: 10px;
  background-color: var(--color-primary);
  color: #fff;
  z-index: 99999;
}

.playlist__media-delete {
  padding: 0.25rem 0.5rem;
  outline: none;
  border: none;
  cursor: pointer;
  border-bottom-left-radius: 10px;
  background-color: hsla(0, 62%, 55%, 1);
  color: #fff;
  border-left: 1px solid #fff;
}

.playlist__media-more {
  padding: 0.25rem 0.5rem;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: hsl(212, 46%, 25%);
  color: #fff;
  border-left: 1px solid #fff;
}

.playlist__card-item .img-overlay {
  position: absolute;
  z-index: 20;
  width: 100%;
  height: 80%;
  bottom: 0px !important;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.56) 100%
  );
}

.playlist__card-item .checked-overlay {
  position: absolute;
  z-index: 100;
  inset: 0;
  background: hsla(209, 53%, 28%, 0.58) !important;
}

.playlist
  .playlist__arrival-wrapper
  .playlist__card
  .ant-tag-checkable-checked {
  background: hsla(209, 53%, 28%, 0.58) !important;
}

.playlist__card-item .checked-overlay > .icon-checked {
  position: absolute;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.playlist__card-img {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.playlist__card-video {
  z-index: 10;
}

.playlist__card-info {
  z-index: 30;
  cursor: default;
  text-align: center;
}

.playlist__card-type {
  background-color: hsla(145, 63%, 42%, 1);
  color: #fff;
  padding: 0.25rem 0.5rem;
  display: inline-block;
  border-bottom-right-radius: 10px;
}
.playlist__card-type-2 {
  background-color: rgb(245, 173, 79);
  color: #fff;
  padding: 0.25rem 0.5rem;
  display: inline-block;
  border-bottom-right-radius: 10px;
}

.playlist__card-order {
  background-color: var(--color-primary);
  color: #fff;
  padding: 0.25rem 0.5rem;
  display: inline-block;
}

.recommend__card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: #ff7875;
  margin-bottom: 1rem;
  
}

.recommend__card-image {
  height: 100%;
  width: 100%;
  object-fit: fill;
}

.image-gallery-recommends {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #F7F8FA;
  padding: 2em;
  border: 4px dashed #22486c;
  border-radius: 4px;
  margin-top: -1em;
}

.image-gallery-recommends .image-gallery-wrapper {
  display: flex;
}

.image-gallery-recommends .image-gallery-wrapper:not(:last-child) {
  margin-bottom: 2em;
  font-size: .8rem;
}

.image-gallery-recommends .image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin: 0px 1em;
  position: relative;
}

.image-gallery-recommends .image-wrapper .image {
  width: 100%;
  height: 150px;
  object-fit: cover;
  margin-bottom: 1rem;
}

.image-gallery-recommends .image-wrapper h3 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
}

.drop-area {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.drop-title {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  margin-top: -1.5em;
}

.drop-title p {
  margin-bottom: 0px;
}

.drop-title p:last-child {
  font-style: italic;
}

.drop-title i {
  margin-left: .25em;
}

.drop-container {
  width: 100%;
  height: 250px;
  background-color: #c2cad9;
  border-radius: 10px;
  padding-top: 1em;
}

.list-draggable {
  background-color: #ccc;
  margin-bottom: 2em;
  padding: 2em;
}

.source-wrapper {
  display: flex;
  justify-content: space-between;
}

.content-wrapper {
  display: flex;
  justify-content: space-between;
}

.no-border {
  border: none !important;
  margin-top: 2em !important;
  padding: 0px !important;
}

.btn-remove {
  position: absolute;
  z-index: 2;
  top: 1em;
  right: 0;
  background-color: #ff4d4f;
  border: none;
  color: #fff;
  border-radius: 50%;
  cursor: pointer;
}

.btn-remove:hover {
  opacity: .8;
}